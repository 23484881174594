import { SysTypes } from "../types";

const initialState = {
	logs: [],
};

const LogReducer = (state = initialState, action) => {
	switch (action.type) {
		case SysTypes.SYS_LOG_LOADED:
			return {
				...state,
				logs: [...action.payload],
			};
			
		default:
			return state;
	}
};

export default LogReducer;
