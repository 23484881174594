import Iconify from "../../components/iconify";

const navConfigDefault = [
	// {
	//   title: "dashboard",
	//   path: "/",
	//   icon: icon("ic_analytics"),
	// },
	{
		title: "Requested",
		path: "/customer/requested",
		icon: <Iconify icon="material-symbols:pending-actions" />,
	},
	{
		title: "Rejected",
		path: "/customer/rejected",
		icon: <Iconify icon="fluent:text-change-reject-24-filled" />,
	},
	{
		title: "In Progress",
		path: "/customer/inprogress",
		icon: <Iconify icon="ri:progress-3-line" />,
	},
	{
		title: "Completed",
		path: "/customer/completed",
		icon: <Iconify icon="ic:twotone-done" />,
	},
];

export default navConfigDefault;
