import moment from "moment";
import dayjs from "dayjs";

import { 
    PAGE_REQUESTED,
        ROLE_ADMIN, 
        ROLE_COMPANY, 
        ROLE_DRIVER, 
        STATUS_CANCELLED, 
        STATUS_COMPLETED, 
        STATUS_DROPPING_OFF, 
        STATUS_GETTING_WASHED, 
        STATUS_IN_QUEUE, 
        STATUS_PICKED_UP, 
        STATUS_REJECTED, 
        STATUS_REQUESTED, 
        STATUS_WAITING_PICKUP, 
        STATUS_WASHED 
    } from "./types/constant";
import Http from "../utils/http";
import { AuthTypes, OrderTypes, SysTypes } from "./types";

export const fetchRequested = (role, name, from, to, pPage) => async (dispatch) => {
    let pfrom = from && dayjs(from).isValid() && dayjs(from).format("YYYY-MM-DD");
    let pto = to && dayjs(to).isValid() && dayjs(to).format("YYYY-MM-DD");

    try {
        const data = await Http.post("/api/order/bystatus/" + STATUS_REQUESTED, 
                                    {
                                        from: pfrom, 
                                        to: pto, 
                                        company: role === ROLE_COMPANY ? name : '', 
                                        role,
                                        page: pPage 
                                    });

        dispatch({type: OrderTypes.ORDER_REQUESTED_LOADED, payload: data.data});
        
        if (pPage === PAGE_REQUESTED) {
            dispatch({type: OrderTypes.ORDER_REQUESTED_LOADED_FOR_WASHERSDRIVERS, payload: data.data});
        }
    } catch(e) {
        console.log(e);
    }
}

export const serverUpdated = () => async (dispatch) => {
    try {
        dispatch({type: OrderTypes.ORDER_UPDATED});
    } catch(e) {
        console.log(e);
    }
}

export const fetchRejected = (role, name, from, to) => async (dispatch) => {
    let pfrom = from && dayjs(from).isValid() && dayjs(from).format("YYYY-MM-DD");
    let pto = to && dayjs(to).isValid() && dayjs(to).format("YYYY-MM-DD");
    
    try {
        const data = await Http.post("/api/order/bystatus/" + STATUS_REJECTED, 
                                    { 
                                        from: pfrom, 
                                        to: pto, 
                                        company: role === ROLE_COMPANY ? name : '', 
                                        role 
                                    });

        dispatch({type: OrderTypes.ORDER_REJECTED_LOADED, payload: data.data});
    } catch(e) {
        console.log(e);
    }
}

export const fetchCancelled = (role, name, from, to) => async (dispatch) => {
    let pfrom = from && dayjs(from).isValid() && dayjs(from).format("YYYY-MM-DD");
    let pto = to && dayjs(to).isValid() && dayjs(to).format("YYYY-MM-DD");

    try {
        const data = await Http.post("/api/order/bystatus/" + STATUS_CANCELLED, 
                                    { 
                                        from: pfrom, 
                                        to: pto, 
                                        company: role === ROLE_COMPANY ? name : '', 
                                        role 
                                    });

        dispatch({type: OrderTypes.ORDER_CANCELLED_LOADED, payload: data.data});
    } catch(e) {
        console.log(e);
    }
}

export const fetchInprogress = (role, name, from, to) => async (dispatch) => {
    let pfrom = from && dayjs(from).isValid() && dayjs(from).format("YYYY-MM-DD");
    let pto = to && dayjs(to).isValid() && dayjs(to).format("YYYY-MM-DD");

    try {
        const data = await Http.post(
            `/api/order/bystatus/${STATUS_WAITING_PICKUP},${STATUS_PICKED_UP},${STATUS_GETTING_WASHED},${STATUS_IN_QUEUE},${STATUS_WASHED},${STATUS_DROPPING_OFF}`, 
            {
                from: role === ROLE_DRIVER ? moment().format('YYYY-MM-DD') : pfrom, 
                to: role === ROLE_DRIVER ? moment().format('YYYY-MM-DD') : pto, 
                company: role === ROLE_COMPANY ? name : '', 
                role 
            }
        );
        
        dispatch({type: OrderTypes.ORDER_INPROGRESS_LOADED, payload: data.data});
    } catch(e) {
        console.log(e);
    }
}

export const fetchInQueue = (pRole, pName, pFrom, pTo) => async (dispatch) => {
    try {
        let url = `/api/order/bystatus/${STATUS_IN_QUEUE}`;
        if (pRole === ROLE_DRIVER) {
            url = `/api/order/bystatus/${STATUS_WAITING_PICKUP}`;
        }

        const data = await Http.post(
            url,
            {
                from: pRole === ROLE_DRIVER ? moment().format('YYYY-MM-DD') : pFrom, 
                to: pRole === ROLE_DRIVER ? moment().format('YYYY-MM-DD') : pTo, 
                company: pRole === ROLE_COMPANY ? pName : '', 
                role: pRole
            }
        );
        
        dispatch({ type:  OrderTypes.ORDER_INQUEUE_LOADED, payload: data.data})
    } catch (err) {
        console.log(err);
    }
}

export const fetchCompleted = (role, name, from, to) => async (dispatch) => {
    let pfrom = from && dayjs(from).isValid() && dayjs(from).format("YYYY-MM-DD");
    let pto = to && dayjs(to).isValid() && dayjs(to).format("YYYY-MM-DD");

    try {
        const data = await Http.post("/api/order/bystatus/" + STATUS_COMPLETED, 
                                    { 
                                        from: role === ROLE_ADMIN ? pfrom : moment().format('YYYY-MM-DD'), 
                                        to: role === ROLE_ADMIN ? pto:moment().format('YYYY-MM-DD'), 
                                        company: role === ROLE_COMPANY ? name : '', 
                                        role 
                                    });

        dispatch({type: OrderTypes.ORDER_COMPLETED_LOADED, payload: data.data});
    } catch(e) {
        console.log(e);
    }
}

export const fetchLog = () => async (dispatch) => {
    try {
        const data = await Http.get("/api/log");
        dispatch({type: SysTypes.SYS_LOG_LOADED, payload: data.data});
    } catch(e) {
        console.log(e);
    }
}

export const logout = () => async (dispatch) => {
    dispatch({type: AuthTypes.LOGOUT_REQUEST});
}