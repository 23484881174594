import PropTypes from "prop-types";

import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
// ----------------------------------------------------------------------

export default function OrderTableRow({
  selected,
  handleClick,
  row,
}) {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected} key={row.id}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleClick} />
        </TableCell>
        <TableCell>{row.Time}-{row.PupNumber}</TableCell>
        <TableCell sx={{lineBreak: 'anywhere', whiteSpace: 'pre-wrap'}}>{row.Message}</TableCell>
      </TableRow>
    </>
  );
}

OrderTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  order: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string,
};
