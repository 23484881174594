export const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: '1px',
	height: '1px',
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	clip: 'rect(0 0 0 0)',
};

export function emptyRows(page, rowsPerPage, arrayLength) {
	return 0; //Math.max(0, (1 + page) * rowsPerPage - arrayLength);
}

function descendingComparator(a, b, orderBy) {
	if (a[orderBy] === null) {
		return 1;
	}

	if (b[orderBy] === null) {
		return -1;
	}

	if (b[orderBy] < a[orderBy]) {
		return -1;
	}

	if (b[orderBy] > a[orderBy]) {
		return 1;
	}

	return 0;
}

export function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function applyFilter({ inputData, comparator, filterName }) {
	const stabilizedThis = inputData.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	inputData = stabilizedThis.map((el) => el[0]);

	if (filterName) {
		inputData = inputData.filter(
			(user) => (user?.Name || user?.Company || "").toLowerCase().indexOf(filterName.toLowerCase()) !== -1
		);
	}

	return inputData;
}

export function capitalize(str) {
	return str.substr(0, 1).toUpperCase() + str.substr(1);
}