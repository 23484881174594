import axios from "axios";

import store from "../redux/store";
import {logout} from "../redux/action"

const Http = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Add a request interceptor
Http.interceptors.request.use(
	(config) => {
		// Get the token from local storage
		const token = localStorage.getItem("authToken");

		// If the token exists, add it to the Authorization header
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		// Handle request error
		return Promise.reject(error);
	}
);

// Response interceptor to handle errors
Http.interceptors.response.use(
	(response) => {
		// Check if there's a new token in the response headers
		const newToken = response.headers['authorization'];
		if (newToken) {
			const token = newToken.split(' ')[1]; // Extract the token part from "Bearer <token>"
			localStorage.setItem("authToken", token); // Save the new token in storage
		}

		return response;
	},
	(error) => {
		// Check if the error response indicates an authentication error
		if (error.response && error.response.status === 403) {
			// Redirect to login page
			store.dispatch(logout());
		}

		return Promise.reject(error);
	}
);

export default Http;
