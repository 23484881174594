import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system";

import Http from "../../../utils/http";
import socket from "../../../socket";

const AddTrailerModal = ({open, setOpen, handleClose, onAdd}) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		// watch,
		// trigger,
		// register,
	} = useForm();
	const [company, setCompany] = useState();
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		Http.get("/api/user/byrole/company")
			.then(companies => setCompanies(companies.data));
	}, []);

	useEffect(() => {
		reset && reset();
	}, [open, reset]);

	const handleOk = (data) => {
		setOpen(false);

		Http.post("/api/truck", {...data, Company: company})
			.then((data) => {
				if (data.data.status === 400) {
					toast.error("Same trailer already exists");
				}

				socket.send("create_trailer");
				onAdd();

				toast.success("Created new trailer successfully");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Creating new trailer failed");
			});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{
				sx: {
					width: "80%", // You can use any valid CSS value here
					maxWidth: "400px", // Optional: you can set a maximum width as well
				},
			}}
		>
			<DialogTitle>Add Trailer</DialogTitle>

			<DialogContent>
				<Typography sx={{ mb: 3 }}>
					Please input the necessary informations for adding a trailer.
				</Typography>

				<form onSubmit={handleSubmit(handleOk)} style={{}}>
					<Autocomplete
						disablePortal
						id="combo-box-company"
						options={companies.map((i) => i.Name)}
						sx={{marginBottom: 1}}
						onChange={(e, v) => {
							setCompany(v);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={!company}
								value={company}
								id="outlined-basic"
								name="company"
								label="Company"
								variant="outlined"
								fullWidth
							/>
						)}
					/>
					<Controller
						name="LeadNumber"
						control={control}
						defaultValue=""
						rules={{ required: "Lead number is required" }}
						render={({ field }) => (
							<TextField
								{...field}
								label="Lead Number"
								variant="outlined"
								style={{ marginBottom: "8px", width: "100%" }}
								error={!!errors.LeadNumber}
								helperText={errors.LeadNumber ? errors.LeadNumber.message : ""}
							/>
						)}
					/>
					<Controller
						name="PupNumber"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextField
								{...field}
								label="Pup Number"
								variant="outlined"
								style={{ marginBottom: "8px", width: "100%" }}
								error={!!errors.PupNumber}
								helperText={errors.PupNumber ? errors.PupNumber.message : ""}
							/>
						)}
					/>
					<Controller
						name="Type"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextField
								{...field}
								label="Type"
								variant="outlined"
								style={{ marginBottom: "8px", width: "100%" }}
								error={!!errors.Type}
								helperText={errors.Type ? errors.Type.message : ""}
							/>
						)}
					/>

					<Stack
						direction={"row"}
						style={{
							justifyContent: "right",
							gap: "8px",
						}}
					>
						<Button variant="outlined" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							style={{ width: "100px" }}
						>
							Add
						</Button>
					</Stack>
				</form>
			</DialogContent>
		</Dialog>
	)
}
export default AddTrailerModal;