import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Main from "../../../pages/admin/main";
import Header from "../header";
import { Box, Container } from "@mui/system";
import NavDefault from "./nav_default";
import { Grid } from "@mui/material";
import AppWidgetSummary from "../../currentstate";
import moment from "moment";
import { 
	STATUS_ACCEPTED, 
	STATUS_COMPLETED, 
	STATUS_DROPPING_OFF, 
	STATUS_GETTING_WASHED, 
	STATUS_IN_QUEUE, 
	STATUS_PICKED_UP, 
	STATUS_REQUESTED, 
	STATUS_WAITING_PICKUP, 
	STATUS_WASHED,
	SERVICE_TYPE_SHUTTLE1WAY,
	SERVICE_TYPE_SHUTTLE2WAY
} from "../../../redux/types/constant";
import { fetchCompleted, fetchInprogress, fetchRequested } from "../../../redux/action";

const WasherLayout = (children = null) => {
	const [openNav, setOpenNav] = useState(false);
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const role = auth.user.job;
	const {inprogress, completed, requested, updateFlag} = useSelector(state => state.orders);
	const requestedFiltered = requested.filter(i => moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
													i.Status === STATUS_REQUESTED
											).length;
	const pending = inprogress.filter(i => moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
											(
												i.Status === STATUS_ACCEPTED || 
												i.Status === STATUS_WAITING_PICKUP || 
												i.Status === STATUS_PICKED_UP || 
												i.Status === STATUS_IN_QUEUE || 
												i.Status === STATUS_GETTING_WASHED
											)
									).length;
	const washed = inprogress.filter(i => 
										moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
										(i.Status === STATUS_WASHED || i.Status === STATUS_DROPPING_OFF) && 
										i.Description !== SERVICE_TYPE_SHUTTLE1WAY && 
										i.Description !== SERVICE_TYPE_SHUTTLE2WAY
								).length 
					+ completed.filter(i => 
										moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
										i.Status === STATUS_COMPLETED && 
										i.Description !== SERVICE_TYPE_SHUTTLE1WAY && 
										i.Description !== SERVICE_TYPE_SHUTTLE2WAY
								).length;
	useEffect(() => {
		fetchCompleted(role, auth.user.name)(dispatch)
		fetchInprogress(role, auth.user.name)(dispatch)
		fetchRequested(role, auth.user.name)(dispatch)
	}, [role, auth.user.name, dispatch, updateFlag]);

  	return (
		<>
			<Header onOpenNav={() => setOpenNav(true)} />
			<Box
				sx={{
					minHeight: 1,
					display: "flex",
					flexDirection: { xs: "column", lg: "row" },
				}}
			>
				<NavDefault openNav={openNav} onCloseNav={() => setOpenNav(false)} />
				<Main>
					<Container maxWidth="xl" style={{ marginBottom: "20px" }}>
						<Grid container justifyContent="center" alignItems="center" spacing={3}>
							<Grid item xs={6} sm={6} md={3}>
								<AppWidgetSummary
									title="Requested Today"
									total={`${requestedFiltered}`}
									color="warning"
									icon={
									<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />
									}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3}>
								<AppWidgetSummary
									title="Washed Today"
									total={`${washed}`}
									color="success"
									icon={
									<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />
									}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3}>
								<AppWidgetSummary
									title="Pending Today"
									total={`${pending}`}
									color="info"
									icon={
									<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />
									}
								/>
							</Grid>
						</Grid>
					</Container>
					<Outlet />
				</Main>
			</Box>
		</>
	);
};

WasherLayout.propTypes = {
	children: PropTypes.node,
};

export default WasherLayout;
