import { BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import { Suspense } from "react";
import { Route } from "react-router-dom";
import { lazy } from "react";
import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import ThemeProvider from "../../src/theme";
import { ROLE_COMPANY, ROLE_DRIVER, ROLE_WASHER } from "../redux/types/constant";

import PageLayout from "../components/layout/default";
import WasherLayout from "../components/layout/washer";
import AdminLayout from "../components/layout/admin";
import RequestedList from "../components/requested";
import RejectedList from "../components/rejected";
import InProgressList from "../components/inprogress";
import CompletedList from "../components/completed";
import CancelledList from "../components/cancelled";
import LogList from "../components/log";

import TrailerManage from "../pages/admin/role/trailer";

const Login = lazy(() => import("../components/auth/Login"));

const LandingPage = lazy(() => import("../pages/landing"));
const UserManage = lazy(() => import("../pages/admin/role/user-role"));

function RoutesDefined() {
	const auth = useSelector(state => state.auth);
	const role = auth.user.job;

	return (
		<ThemeProvider>
			<Router>
				<Suspense
					fallback={
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100vh",
							}}
						>
							<CircularProgress size={70} />
						</div>
					}
				>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/" element={<LandingPage />} />
						<Route path="/customer" element={auth.isLoggedIn?role!==ROLE_COMPANY?<WasherLayout />:<PageLayout />:<Navigate to="/login" />}>
							<Route path="requested" element={<RequestedList />} />
							<Route path="rejected" element={<RejectedList />} />
							<Route path="inprogress" element={<InProgressList />} />
							<Route path="completed" element={<CompletedList />} />
						</Route>
						<Route path="admin" element={auth.isLoggedIn?<AdminLayout />:<Navigate to="/login" />}>
							<Route index element={<UserManage role={ROLE_COMPANY} />} />
							<Route path="driver-role" element={<UserManage role={ROLE_DRIVER} />} />
							<Route path="washer-role" element={<UserManage role={ROLE_WASHER} />} />
							<Route path="trailer" element={<TrailerManage />} />
							<Route path="cancelled" element={<CancelledList />} />
							<Route path="rejected" element={<RejectedList />} />
							<Route path="inprogress" element={<InProgressList />} />
							<Route path="completed" element={<CompletedList />} />
							<Route path="log" element={<LogList />} />
						</Route>
					</Routes>
				</Suspense>
			</Router>
		</ThemeProvider>
	);
}

export default RoutesDefined;