// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const STATUS_REQUESTED = "requested";
export const STATUS_REJECTED = "rejected";
export const STATUS_ACCEPTED = "accepted";
export const STATUS_WAITING_PICKUP = "waiting_pickup";
export const STATUS_PICKED_UP = "picked_up";
export const STATUS_WASHED = "washed";
export const STATUS_GETTING_WASHED = "getting_washed";
export const STATUS_IN_QUEUE = "in_queue";
export const STATUS_DROPPING_OFF = "dropping_off";
export const STATUS_COMPLETED = "completed";
export const STATUS_CANCELLED = "cancelled";

export const SERVICE_TYPE_WASH = "Wash";
export const SERVICE_TYPE_PICKWASH = "Pickup & Wash";
export const SERVICE_TYPE_SHUTTLE1WAY = "Shuttle 1 Way";
export const SERVICE_TYPE_SHUTTLE2WAY = "Shuttle 2 Way";

export const ROLE_ADMIN = "admin";
export const ROLE_COMPANY = "company";
export const ROLE_DRIVER = "driver";
export const ROLE_WASHER = "washer";

export const PAGE_REQUESTED = "requested";
export const PAGE_REJECTED = "rejected";
export const PAGE_INPROGRESS = "in progress";
export const PAGE_COMPLETED = "completed";