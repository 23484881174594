import { useState } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import Iconify from "../../../components/iconify/index";
import Scrollbar from "../../../components/scrollbar/index";
import TableNoData from "./table-no-data";
import TrailerTableRow from "./trailer-table-row";
import TrailerTableHead from "./trailer-table-head";
import TableEmptyRows from "./table-empty-rows";
import TrailerTableToolbar from "./trailer-table-toolbar";
import { emptyRows, applyFilter, getComparator } from "../utils";
import AddTrailerModal from "./trailer-add";
import UpdateTrailerModal from "./trailer-update";
// ----------------------------------------------------------------------

export default function TrailerPage(prop) {
	const { trailers, getTrailers } = prop;

	const [page, setPage] = useState(0);
	const [order, setOrder] = useState("desc");
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState("id");
	const [filterName, setFilterName] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [open, setOpen] = useState(false);
	const [updateId, setUpdateId] = useState("");
	const [updateflag, setUpdateFlag] = useState(false);
	const [initData, setInitData] = useState({});

	const handleSort = (event, id) => {
		const isAsc = orderBy === id && order === "asc";

		if (id !== "") {
			setOrder(isAsc ? "desc" : "asc");
			setOrderBy(id);
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setUpdateFlag(false);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = trailers.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}

		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);

		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const dataFiltered = applyFilter({
		inputData: trailers,
		comparator: getComparator(order, orderBy),
		filterName,
	});

  	const notFound = !dataFiltered.length && !!filterName;

	return (
		<>
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4">Trailer</Typography>

					<Button
						variant="contained"
						color="inherit"
						startIcon={<Iconify icon="eva:plus-fill" />}
						onClick={handleOpen}
					>
						New Trailer
					</Button>
				</Stack>

				<Card>
					<TrailerTableToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>

					<Scrollbar>
						<TableContainer sx={{ overflow: "unset" }}>
							<Table sx={{ minWidth: 800 }}>
								<TrailerTableHead
									order={order}
									orderBy={orderBy}
									rowCount={trailers.length}
									numSelected={selected.length}
									onRequestSort={handleSort}
									onSelectAllClick={handleSelectAllClick}
									headLabel={[
										{ id: "Company", label: "Company" },
										{ id: "Lead", label: "Lead" },
										{ id: "Pup", label: "Pup" },
										{ id: "Type", label: "Type" },
										// { id: "isVerified", label: "Verified", align: "center" },
										// { id: "State", label: "Status" },
										{ id: "" },
									]}
								/>
								<TableBody>
									{dataFiltered
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TrailerTableRow
												key={row.id}
												id={row.id}
												row={row}
												getTrailers={getTrailers}
												setUpdateFlag={setUpdateFlag}
												setUpdateId={id => {
													setInitData(dataFiltered.find(d => d.id === id) || {});
													setUpdateId(id)
												}}
												// avatarUrl={row.avatarUrl}
												// isVerified={row.isVerified}
												selected={selected.indexOf(row.id) !== -1}
												handleClick={(event) => handleClick(event, row.id)}
											/>
									))}

									<TableEmptyRows
										height={77}
										emptyRows={emptyRows(page, rowsPerPage, trailers.length)}
									/>

									{notFound && <TableNoData query={filterName} />}
								</TableBody>
							</Table>
						</TableContainer>
					</Scrollbar>

					<TablePagination
						page={page}
						component="div"
						count={trailers.length}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						rowsPerPageOptions={[5, 10, 25]}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>

			<AddTrailerModal 
				open={open} 
				setOpen={setOpen} 
				onAdd={prop.getTrailers} 
				handleClose={handleClose} 
			/>

			{ updateflag ? 
				<UpdateTrailerModal 
					initData={initData} 
					updateflag={updateflag} 
					setUpdateFlag={setUpdateFlag} 
					onUpdate={prop.getTrailers} 
					handleClose={handleClose} 
					updateId={updateId} 
				/> 
				:
				<></>
			}
		</>
	);
}
