import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form"
import Http from "../../../utils/http";
import { useEffect, useState } from "react";
import socket from "../../../socket";

const UpdateTrailerModal = ({updateflag, setUpdateFlag, handleClose, onUpdate, updateId, initData}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    // watch,
    // register,
    // trigger,
  } = useForm();
  const [company, setCompany] = useState(initData.Company);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    Http.get("/api/user/byrole/company").then(companies => setCompanies(companies.data));
  }, []);
  const handleUpdateOk = (data) => {
    Http.put(`/api/truck/${updateId}`, {...data, Company: company})
      .then((data) => {
        socket.send("update_trailer");
        onUpdate();
      })
      .catch((err) => {});
    setUpdateFlag(false);
  };
  return (
    <Dialog
      open={updateflag}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "80%", // You can use any valid CSS value here
          maxWidth: "400px", // Optional: you can set a maximum width as well
        },
      }}
    >
      <DialogTitle>Update trailer</DialogTitle>

      <DialogContent>
        <Typography sx={{ mb: 3 }}>
          Please input the necessary informations for updating a trailer.
        </Typography>

        <form onSubmit={handleSubmit(handleUpdateOk)} style={{}}>
        <Autocomplete
          disablePortal
          id="combo-box-company"
          options={companies.map((i) => i.Name)}
          defaultValue={company}
          sx={{marginBottom: 1}}
          onChange={(e, v) => {
              setCompany(v);
            }
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={!company}
              value={company}
              id="outlined-basic"
              name="company"
              label="Company"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="LeadNumber"
          control={control}
          defaultValue={initData.LeadNumber}
          rules={{ required: "Lead number is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Lead Number"
              variant="outlined"
              style={{ marginBottom: "8px", width: "100%" }}
              error={!!errors.LeadNumber}
              helperText={errors.LeadNumber ? errors.LeadNumber.message : ""}
            />
          )}
        />
        <Controller
          name="PupNumber"
          control={control}
          defaultValue={initData.PupNumber}
          render={({ field }) => (
            <TextField
              {...field}
              label="Pup Number"
              variant="outlined"
              style={{ marginBottom: "8px", width: "100%" }}
              error={!!errors.PupNumber}
              helperText={errors.PupNumber ? errors.PupNumber.message : ""}
            />
          )}
        />
        <Controller
          name="Type"
          control={control}
          defaultValue={initData.Type}
          render={({ field }) => (
            <TextField
              {...field}
              label="Type"
              variant="outlined"
              style={{ marginBottom: "8px", width: "100%" }}
              error={!!errors.Type}
              helperText={errors.Type ? errors.Type.message : ""}
            />
          )}
        />

          <Stack
            direction={"row"}
            style={{
              justifyContent: "right",
              gap: "8px",
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ width: "100px" }}
            >
              Update
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>)
}
export default UpdateTrailerModal;