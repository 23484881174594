import { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { ListSubheader } from "@mui/material";

import { usePathname } from "../../../router/hooks/index";
import { RouterLink } from "../../../router/components/index";

import { useResponsive } from "../../../hooks/use-responsive";

import Scrollbar from "../../scrollbar/index";

import { NAV } from "../../../pages/admin/config-layout";
import navConfig from "../../../pages/admin/config-navigation-admin";

import logo from "../../../assets/images/photo.png";

export default function Nav({ openNav, onCloseNav }) {
	const auth = useSelector(state => state.auth);
	const pathname = usePathname();
	const upLg = useResponsive("up", "lg");

	useEffect(() => {
		if (openNav) {
			onCloseNav();
		}
	}, [pathname, openNav, onCloseNav]);

	const renderAccount = (
		<Box
			sx={{
				my: 3,
				mx: 2.5,
				py: 2,
				px: 2.5,
				display: "flex",
				borderRadius: 1.5,
				alignItems: "center",
				bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
			}}
		>
			<Avatar src={auth.user.avatar} alt="photoURL" />
			<Box sx={{ ml: 2, width: 0, flexGrow: 1 }}>
				<Typography variant="subtitle2">
					{auth.user.email}
				</Typography>
				<Typography variant="body2" sx={{ color: "text.secondary" }}>
					{auth.user.job}
				</Typography>
			</Box>
		</Box>
	);

	const renderMenu = (
		<Stack component="nav" spacing={0.5} sx={{ px: 2, mt: 5 }}>
			{navConfig.map((item, idx) => (
				<NavItem key={idx} item={item} />
			))}
		</Stack>
	);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				"& .simplebar-content": {
					height: 1,
					display: "flex",
					flexDirection: "column",
				},
			}}
		>
			{/* <Logo sx={{ mt: 3, ml: 4 }} /> */}
			<img
				src={logo}
				alt="logo"
				style={{
					display: "flex",
					alignContent: "center",
					width: "200px",
					marginLeft: "24px",
					marginTop: "10px",
					filter: 'drop-shadow(2px 2px 4px black)'
				}}
			/>

			{renderAccount}

			{renderMenu}

			<Box sx={{ flexGrow: 1 }} />
		</Scrollbar>
	);

	return (
		<Box
			sx={{
			flexShrink: { lg: 0 },
			width: { lg: NAV.WIDTH },
			}}
		>
			{upLg ? (
			<Box
				sx={{
					height: 1,
					position: "fixed",
					width: NAV.WIDTH,
					borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
				}}
			>
				{renderContent}
			</Box>
			) : (
			<Drawer
				open={openNav}
				onClose={onCloseNav}
				PaperProps={{
					sx: {
						width: NAV.WIDTH,
					},
				}}
			>
				{renderContent}
			</Drawer>
			)}
		</Box>
	);
}

Nav.propTypes = {
	openNav: PropTypes.bool,
	onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
	const pathname = usePathname();
	const active = item.path === pathname;

	if(item.seperator) {
		return (
			<>
				{/* <hr/> */}
				<ListSubheader sx={{background: 'transparent'}}>
					{item.label}
				</ListSubheader>
			</>
		)
	}

	return (
		<ListItemButton
			component={RouterLink}
			href={item.path}
			sx={{
				minHeight: 44,
				borderRadius: 0.75,
				typography: "body2",
				color: "text.secondary",
				textTransform: "capitalize",
				fontWeight: "fontWeightMedium",
				...(active && {
					color: "primary.main",
					fontWeight: "fontWeightSemiBold",
					bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
					"&:hover": {
						bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
					},
				}),
			}}
		>
			<Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
				{item.icon}
			</Box>
			<Box component="span">
				{item.title}
			</Box>
		</ListItemButton>
	);
}

NavItem.propTypes = {
	item: PropTypes.object,
};
