import { OrderTypes } from "../types";

const initialState = {
	requested: [],
	requested_for_washersdrivers: [],
	rejected: [],
	inprogress: [],
	completed: [],
	cancelled: [],
	inqueue: [],
	updateFlag: {},
	isLoading: false,
	errorMessage: "",
};

const OrderReducer = (state = initialState, action) => {
	switch (action.type) {
		case OrderTypes.ORDER_REQUESTED_LOADED:
			return {
				...state,
				requested: action.payload,
			};
		case OrderTypes.ORDER_REQUESTED_LOADED_FOR_WASHERSDRIVERS:
			return {
				...state,
				requested_for_washersdrivers: action.payload,
			};
		case OrderTypes.ORDER_REJECTED_LOADED:
			return {
				...state,
				rejected: action.payload,
			};
		case OrderTypes.ORDER_CANCELLED_LOADED:
			return {
				...state,
				cancelled: action.payload,
			};
		case OrderTypes.ORDER_INPROGRESS_LOADED:
			return {
				...state,
				inprogress: action.payload,
			};
		case OrderTypes.ORDER_COMPLETED_LOADED:
			return {
				...state,
				completed: action.payload,
			};
		case OrderTypes.ORDER_UPDATED:
			return {
				...state,
				updateFlag: {},
			};
		case OrderTypes.ORDER_INQUEUE_LOADED:
			return {
				...state,
				inqueue: action.payload,
			};
		default:
			return state;
	}
};

export default OrderReducer;
