import { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Button, Dialog, DialogContent, DialogTitle, Unstable_Grid2 as Grid, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { MobileTimePicker } from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";

import moment from "moment";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";

import Iconify from "../../../components/iconify";
import Http from "../../../utils/http";
import { ROLE_COMPANY, ROLE_DRIVER, ROLE_WASHER, SERVICE_TYPE_PICKWASH, SERVICE_TYPE_SHUTTLE1WAY, SERVICE_TYPE_SHUTTLE2WAY, SERVICE_TYPE_WASH, STATUS_COMPLETED, STATUS_DROPPING_OFF, STATUS_GETTING_WASHED, STATUS_IN_QUEUE, STATUS_PICKED_UP, STATUS_REQUESTED, STATUS_WAITING_PICKUP, STATUS_WASHED } from "../../../redux/types/constant";
import Label from "../../../components/label";
import socket from "../../../socket";

// ----------------------------------------------------------------------

export default function OrderTableRow({
	selected,
	handleClick,
	getOrders,
	row,
	role,
	tab,
}) {
	const [open, setOpen] = useState(null);
	const [dateNew, setDateNew] = useState(dayjs());
	const [openRejecter, setOpenRejecter] = useState(false);
	const [openResender, setOpenResender] = useState(false);
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const onResend = () => {
		setOpenResender(true);
		reset();
	};
	const onCancelOrder = () => {
		Http.post("/api/order/cancel", { id: row.id })
			.then((data) => {
				socket.send("cancel_order");
				getOrders();

				toast.success("Successfully cancelled.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const onComplete = () => {
		handleCloseMenu();

		Http.post("/api/order/complete", { id: row.id })
			.then((data) => {
				socket.send("complete_order");
				getOrders();

				toast.success("Successfully marked as completed.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const onDroppingOff = () => {
		handleCloseMenu();

		Http.post("/api/order/droppingOff", { id: row.id })
			.then((data) => {
				socket.send("dropping_off_order");
				getOrders();

				toast.success("Successfully marked as dropping-off.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const onPickup = () => {
		handleCloseMenu();

		Http.post("/api/order/pickup", { id: row.id })
			.then((data) => {
				socket.send("pickup_order");
				getOrders();

				toast.success("Successfully marked as picked up.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const onGettingWash = () => {
		handleCloseMenu();

		Http.post("/api/order/startWash", { id: row.id })
			.then((data) => {
				socket.send("start_wash_order");
				getOrders();

				toast.success("Successfully marked as getting washed.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const onInQueue = () => {
		handleCloseMenu();

		Http.post("/api/order/queue", { id: row.id })
			.then((data) => {
				socket.send("queue_order");
				getOrders();

				toast.success("Successfully marked as in-queue.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleCloseResend = () => {
		setOpenResender(false);
	};
	const handleOkResend = (data) => {
		setOpenResender(false);

		Http.put("/api/order/bystatus/" + STATUS_REQUESTED, { id: row.id, date: dayjs(dateNew).format("YYYY-MM-DD HH:mm") })
			.then((data) => {
				socket.send("resend_order");
				getOrders();

				toast.success(" Request successfully resent.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const endWash = () => {
		handleCloseMenu();

		Http.post("/api/order/endWash", { id: row.id })
			.then((data) => {
				socket.send("end_wash_order");

				getOrders();

				toast.success(" Successfully marked as washed.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {});
	};

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleCloseRejector = () => {
		setOpenRejecter(false);
	};
	const handleAcceptMenu = () => {
		// handleCloseMenu();
		// setUpdateFlag(true);
		// setUpdateId(id);

		handleCloseMenu();
		Http.post(`/api/order/accept`, {id: row.id})
			.then((data) => {
				socket.send("accept_order");
				toast.success(" Request successfully accepted.", {
					hideProgressBar: true,
				});
				getOrders();
			})
			.catch((err) => {});
	};
	const handleRejectMenu = () => {
		handleCloseMenu();
		setOpenRejecter(true);
	};
	const handleOkToReject = (data) => {
		setOpenRejecter(false);

		Http.post("/api/order/reject", { id: row.id, ...data })
			.then((data) => {
				socket.send("reject_order");
				getOrders();
				toast.success(" Request successfully rejected.", {
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const statusColor = {
		[STATUS_WAITING_PICKUP]: "error",
		[STATUS_PICKED_UP]: "error",
		[STATUS_IN_QUEUE]: "warning",
		[STATUS_GETTING_WASHED]: "warning",
		[STATUS_WASHED]: "success",
		[STATUS_DROPPING_OFF]: "success",
		[STATUS_COMPLETED]: "success",
	}
	const statusLabel = (status) => {
		return status.split("_").map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(" ");
	}

  	return (
		<>
			<TableRow hover tabIndex={-1} role="checkbox" selected={selected} key={row.id}>
				<TableCell padding="checkbox">
					<Checkbox disableRipple checked={selected} onChange={handleClick} />
				</TableCell>
				<TableCell>
					{row.LeadNumber}{row.PupNumber && ("-" + row.PupNumber)}
					{row.SingleTrailerNo}
				</TableCell>
				{
				role!==ROLE_COMPANY && 
				<TableCell>
					{row.Company}
				</TableCell>
				}
				<TableCell>
					{row.Description}
				</TableCell>
				<TableCell>
					{row.Pickup}
				</TableCell>
				<TableCell>
					{row.Drop}
				</TableCell>
				{
				tab==="rejected" && 
				<TableCell>
					{row.Reason}
				</TableCell>
				}
				{
				(tab==="inprogress" || tab==="completed") && 
				<TableCell>
					<Label color={statusColor[row.Status]}>
						{statusLabel(row.Status)}
					</Label>
				</TableCell>
				}
				<TableCell>
					{moment(row.Date).format('YYYY-MM-DD\u00A0\u00A0\u00A0HH:mm')}
				</TableCell>
				{
				(
					(
						(
							(role===ROLE_WASHER && (row.Description===SERVICE_TYPE_WASH || row.Description===SERVICE_TYPE_PICKWASH)) || 
							(role===ROLE_DRIVER && (row.Description===SERVICE_TYPE_SHUTTLE1WAY || row.Description===SERVICE_TYPE_SHUTTLE2WAY))
						) && tab==="requested"
					) || 
					(
						role===ROLE_WASHER && 
						(
							[STATUS_IN_QUEUE, STATUS_GETTING_WASHED].includes(row.Status) || 
							(row.Status===STATUS_WASHED && row.Description===SERVICE_TYPE_WASH)
						) && 
						tab==="inprogress"
					) ||
					(
						(role===ROLE_DRIVER || role===ROLE_COMPANY) && 
						tab==="rejected"
					) ||
					(
						role===ROLE_DRIVER && 
						[STATUS_DROPPING_OFF,STATUS_WASHED,STATUS_WAITING_PICKUP,STATUS_PICKED_UP,STATUS_IN_QUEUE].includes(row.Status) && 
						tab==="inprogress"
					)
				) && 
				<TableCell align="right">
					<IconButton onClick={handleOpenMenu}>
						<Iconify icon="eva:more-vertical-fill" />
					</IconButton>
				</TableCell>
				}
			</TableRow>

			{
			(
				(role===ROLE_WASHER && (row.Description===SERVICE_TYPE_WASH || row.Description===SERVICE_TYPE_PICKWASH)) || 
				(role===ROLE_DRIVER && (row.Description===SERVICE_TYPE_SHUTTLE1WAY || row.Description===SERVICE_TYPE_SHUTTLE2WAY))
			) && 
			tab==="requested" && 
			<Popover
				open={!!open}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: { width: 140 },
				}}
			>
				<MenuItem onClick={handleAcceptMenu}>
					<Iconify icon="fluent:text-change-accept-24-filled" sx={{ mr: 2 }} />
					Accept
				</MenuItem>
				<MenuItem onClick={handleRejectMenu} sx={{ color: "error.main" }}>
					<Iconify icon="fluent:text-change-reject-24-filled" sx={{ mr: 2 }} />
					Reject
				</MenuItem>
			</Popover>
			}

			{ 
			role===ROLE_WASHER && 
			row.Status===STATUS_GETTING_WASHED && 
			tab==="inprogress" && 
			<Popover
				open={!!open}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: { width: 140 },
				}}
			>
				<MenuItem onClick={endWash}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Washed
				</MenuItem>
			</Popover>
			}

			{
			role===ROLE_WASHER && 
			row.Status===STATUS_IN_QUEUE && 
			tab==="inprogress" && 
			<Popover
				open={!!open}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: { width: 250 },
				}}
			>
				{
				(row.Status===STATUS_PICKED_UP || row.Status===STATUS_IN_QUEUE) && 
				<MenuItem onClick={onGettingWash}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Getting-Washed
				</MenuItem>
				}
				{
				row.Status===STATUS_WASHED && 
				row.Description===SERVICE_TYPE_WASH && 
				<MenuItem onClick={onComplete}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Completed
				</MenuItem>
				}
			</Popover>
			}

			{
			(role===ROLE_DRIVER || role===ROLE_COMPANY) && 
			tab==="rejected" && 
			<Popover
				open={!!open}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: { width: 140 },
				}}
			>
				<MenuItem onClick={onResend}>
					<Iconify icon="mdi:email-resend-outline" sx={{ mr: 2 }} />
					Resend
				</MenuItem>
				<MenuItem onClick={onCancelOrder} sx={{ color: "error.main" }}>
					<Iconify icon="mdi:cancel" sx={{ mr: 2, color: "error.main" }} />
					Cancel
				</MenuItem>
			</Popover>
			}

			{
			role===ROLE_DRIVER && 
			tab==="inprogress" && 
			<Popover
				open={!!open}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: { width: 250 },
				}}
			>
				{
				(
					row.Status===STATUS_DROPPING_OFF || 
					(row.Status===STATUS_PICKED_UP && (row.Description===SERVICE_TYPE_SHUTTLE1WAY || row.Description===SERVICE_TYPE_SHUTTLE2WAY))
				) && 
				<MenuItem onClick={onComplete}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Completed
				</MenuItem>
				}
				{ 
				row.Status===STATUS_WASHED && 
				<MenuItem onClick={onDroppingOff}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Dropping-Off
				</MenuItem>
				}
				{
				row.Status===STATUS_WAITING_PICKUP && 
				<MenuItem onClick={onPickup}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Picked-Up
				</MenuItem>
				}
				{ 
				row.Status===STATUS_PICKED_UP && 
				row.Description!==SERVICE_TYPE_SHUTTLE1WAY && 
				row.Description!==SERVICE_TYPE_SHUTTLE2WAY && 
				<MenuItem onClick={onInQueue}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as In-Queue
				</MenuItem>
				}
				{
				(
					(row.Status===STATUS_PICKED_UP || row.Status===STATUS_IN_QUEUE) && 
					row.Description!==SERVICE_TYPE_SHUTTLE1WAY && 
					row.Description!==SERVICE_TYPE_SHUTTLE2WAY
				) && 
				<MenuItem onClick={onGettingWash}>
					<Iconify icon="fluent-mdl2:accept-medium" sx={{ mr: 2 }} />
					Mark as Getting-Washed
				</MenuItem>
				}
			</Popover>
			}

			<Dialog
				open={openRejecter}
				onClose={handleCloseRejector}
				PaperProps={{
					sx: {
						width: "80%", // You can use any valid CSS value here
						maxWidth: "400px", // Optional: you can set a maximum width as well
					},
				}}
			>
				<DialogTitle>Reject</DialogTitle>
				<DialogContent>
					<Typography sx={{ mb: 3 }}>
						Please input the reason to reject.
					</Typography>

					<form onSubmit={handleSubmit(handleOkToReject)} style={{}}>
						<Controller
							name="description"
							control={control}
							defaultValue=""
							rules={{ required: "Description is required", maxLength: {value: 150, message: 'Maximum 150 characters allowed'}}}
							render={({ field }) => (
								<TextField
									{...field}
									label="Description"
									variant="outlined"
									style={{ marginBottom: "8px", width: "100%" }}
									error={!!errors.description}
									helperText={errors.description ? errors.description.message : ""}
								/>
							)} />

						<Stack
							direction={"row"}
							style={{
								justifyContent: "right",
								gap: "8px",
							}}
						>
							<Button variant="outlined" onClick={handleCloseRejector}>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								style={{ width: "100px" }}
							>
								Reject
							</Button>
						</Stack>
					</form>
				</DialogContent>
			</Dialog>

			<Dialog
				open={openResender}
				onClose={handleCloseResend}
				PaperProps={{
				sx: {
					width: "80%", // You can use any valid CSS value here
					maxWidth: "400px", // Optional: you can set a maximum width as well
				},
				}}
			>
				<DialogTitle>Request Again</DialogTitle>

				<DialogContent>
					<Typography sx={{ mb: 3 }}>Please select new date and time.</Typography>

					<form onSubmit={handleSubmit(handleOkResend)} style={{}}>
						<Controller
							name="date"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<Grid xs={12} md={6}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateCalendar
											value={dateNew}
											onChange={(newValue) => setDateNew(dayjs(newValue))}
											sx={{ width: "270px" }}
										/>
										<MobileTimePicker 
											value={dateNew}
											onChange={(newValue) => setDateNew(dayjs(newValue))}
											sx={{ width: "270px", marginBottom: 1 }}
										/>
									</LocalizationProvider>
								</Grid>
							)} />

						<Stack
							direction={"row"}
							style={{
								justifyContent: "right",
								gap: "8px",
							}}
						>
							<Button variant="outlined" onClick={handleCloseResend}>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								style={{ width: "100px" }}
							>
								Request
							</Button>
						</Stack>
					</form>
				</DialogContent>
			</Dialog>
		</>
  	);
}

OrderTableRow.propTypes = {
	avatarUrl: PropTypes.any,
	order: PropTypes.any,
	handleClick: PropTypes.func,
	isVerified: PropTypes.any,
	name: PropTypes.any,
	role: PropTypes.any,
	selected: PropTypes.any,
	status: PropTypes.string,
};
