import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

import { Box, Container } from "@mui/system";
import { Grid } from "@mui/material";

import NavAdmin from "./nav_admin";
import Main from "../../../pages/admin/main";
import Header from "../header";
import AppWidgetSummary from "../../currentstate";


import { STATUS_ACCEPTED, STATUS_COMPLETED, STATUS_REQUESTED, STATUS_WASHED } from "../../../redux/types/constant";
import { fetchCompleted, fetchInprogress, fetchRequested } from "../../../redux/action";

export default function AdminLayout({ children }) {
	const [openNav, setOpenNav] = useState(false);
	

	const dispatch = useDispatch();
	const auth = useSelector(state => state.auth);
	const role = auth.user.job;
	const {inprogress, completed, requested} = useSelector(state => state.orders);

	const requestedFiltered = requested.filter(i => 
												moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
												i.Status === STATUS_REQUESTED
											).length;
	const pending = inprogress.filter(i => 
										moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
										i.Status === STATUS_ACCEPTED
									).length;
	const washed = inprogress.filter(i => 
										moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
										i.Status === STATUS_WASHED
									).length 
				+ completed.filter(i => 
										moment(i.Date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && 
										i.Status === STATUS_COMPLETED
								).length;
	useEffect(() => {
		fetchCompleted(role, auth.user.name)(dispatch)
		fetchInprogress(role, auth.user.name)(dispatch)
		fetchRequested(role, auth.user.name)(dispatch)
	}, [role, auth.user.name, dispatch]);

	return (
		<>
			<Header onOpenNav={() => setOpenNav(true)} />

			<Box
				sx={{
					minHeight: 1,
					display: "flex",
					flexDirection: { xs: "column", lg: "row" },
				}}
			>
				<NavAdmin openNav={openNav} onCloseNav={() => setOpenNav(false)} />

				<Main>
					<Container maxWidth="xl" style={{ marginBottom: "20px" }}>
						<Grid container justifyContent="center" alignItems="center" spacing={3}>
							<Grid item xs={6} sm={6} md={3}>
								<AppWidgetSummary
									title="Requested Today"
									total={`${requestedFiltered}`}
									color="warning"
									icon={
										<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />
									}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3}>
								<AppWidgetSummary
									title="Washed Today"
									total={`${washed}`}
									color="success"
									icon={
										<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />
									}
								/>
							</Grid>
							<Grid item xs={6} sm={6} md={3}>
								<AppWidgetSummary
									title="Pending Today"
									total={`${pending}`}
									color="info"
									icon={
										<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />
									}
								/>
							</Grid>
						</Grid>
					</Container>
					
					<Outlet />
				</Main>
			</Box>
		</>
	);
}

AdminLayout.propTypes = {
	children: PropTypes.node,
};
