// src/socket.js
import io from 'socket.io-client';
import store from './redux/store';
import { serverUpdated } from './redux/action';

const socket = io(process.env.REACT_APP_BACKEND_URL);

socket.on('message', (message) => {
  store.dispatch(serverUpdated());
});

export default socket;