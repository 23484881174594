import React, { useState, useEffect } from "react";

import Http from "../../../utils/http";
import TrailerPage from "../../table/trailer/trailer-view";
import { useSelector } from "react-redux";

const TrailerManage = () => {
	const [trailers, setTrailers] = useState([]);
	const {updateFlag} = useSelector(state => state.orders);

	useEffect(() => {
		getTrailers();
	}, [updateFlag]);

	const getTrailers = () => {
		Http.get("/api/truck")
			.then((data) => {
				setTrailers(data.data);
			})
			.catch((err) => {});
	};

	return (
		<>
			<TrailerPage trailers={trailers} getTrailers={getTrailers} />
		</>
	);
};

export default TrailerManage;