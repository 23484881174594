import { combineReducers } from "redux";

import AuthReducer from "./auth.reducer";
import OrderReducer from "./order.reducer";
import LogReducer from "./log.reducer";

const allReducers = {
	// Put all of the reducers here!
	auth: AuthReducer,
	orders: OrderReducer,
	logs: LogReducer,
};

const rootReducer = combineReducers(allReducers);

export default rootReducer;