import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Container,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import { MobileTimePicker } from "@mui/x-date-pickers";
import { 
	Select, 
	MenuItem, 
	FormControl, 
	InputLabel 
} from '@mui/material';

import Iconify from "./iconify";
import { 
	ROLE_DRIVER, 
	ROLE_WASHER, 
	SERVICE_TYPE_PICKWASH, 
	SERVICE_TYPE_SHUTTLE1WAY, 
	SERVICE_TYPE_SHUTTLE2WAY, 
	SERVICE_TYPE_WASH 
} from "../redux/types/constant";
import socket from "../socket";
import Http from "../utils/http";

const GenerateTimeIntervals = () => {
	const intervals = [];
	const startOfDay = dayjs().startOf('day');
	const endOfDay = dayjs().endOf('day');

	for (let time = startOfDay; time.isBefore(endOfDay); time = time.add(15, 'minute')) {
		intervals.push(time.format('hh:mm A'));
	}

	return intervals;
}

export const RequestTask = ({ toggleDrawer, refreshList, role }) => {
	const auth = useSelector((state) => state.auth);
	const [company, setCompany] = useState(auth.user.name);
	const [showErrors, setShowErrors] = useState(false);
	const [date, setDate] = useState(dayjs());
	const [description, setDescription] = useState("");
	const [leadNumber, setLeadNumber] = useState("");
	const [pupNumber, setPupNumber] = useState("");

	const [pickups, setPickups] = useState([]);
	const [drops, setDrops] = useState([]);

	const [pickup, setPickup] = useState("");
	const [drop, setDrop] = useState("");

	const [trucks, setTrucks] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [isSingleTrailer, setIsSingleTrailer] = useState(false);

	const onSingleTrailerChecked = (ev, checked) => {
		setIsSingleTrailer(checked);
	}
  
	const [serviceTypes, setServiceTypes] = useState([SERVICE_TYPE_WASH, SERVICE_TYPE_PICKWASH, SERVICE_TYPE_SHUTTLE1WAY, SERVICE_TYPE_SHUTTLE2WAY]);

	const timeIntervals = GenerateTimeIntervals();
	const [selectedTime, setSelectedTime] = useState('12:00 AM')

	const [singleTrailers, setSingleTrailers] = useState([]);
	const [singleTrailerNo, setSingleTrailerNo] = useState("");

	useEffect(() => {
		(async function () {
			if(role === ROLE_DRIVER) {
				const trucks = await Http.get("/api/truck");
				setTrucks(trucks.data);

				setServiceTypes([SERVICE_TYPE_PICKWASH, SERVICE_TYPE_SHUTTLE1WAY, SERVICE_TYPE_SHUTTLE2WAY]);
			} else if(role === ROLE_WASHER) {
				const trucks = await Http.get("/api/truck");
				setTrucks(trucks.data);

				setServiceTypes([SERVICE_TYPE_WASH]);
			} else {
				const trucks = await Http.get("/api/truck/by/" + encodeURIComponent(company));
				setTrucks(trucks.data);
			}

			const companies = await Http.get("/api/user/byrole/company");
			setCompanies(companies.data);

			const pickup_locations = await Http.get("/api/pickup_location");
			const drop_locations = await Http.get("/api/drop_location");
			setDrops(drop_locations.data);
			setPickups(pickup_locations.data);
		})();
	}, [company, role]);

	useEffect(() => {
		(async function () {
			const singleTrailers = await Http.get("/api/single_trailer");
			setSingleTrailers(singleTrailers.data);
		})();
	}, [])

	const validate = () => {
		if (
			(
				!isSingleTrailer && leadNumber === ""
			) || 
			(
				(role===ROLE_DRIVER || role===ROLE_WASHER) ? company === "" : false
			) || 
			dayjs(date).isBefore(dayjs(), "day") || 
			(
				(description === SERVICE_TYPE_SHUTTLE1WAY || description === SERVICE_TYPE_SHUTTLE2WAY) && 
				(!pickup || !drop)
			) || 
			(
				isSingleTrailer ? 
					!isSingleTrailerNoFollowingPattern(singleTrailerNo) || singleTrailerNo === '' 
					: false
			) || 
			(
				!description
			)
		) {
			setShowErrors(true);
		} else {
			toggleDrawer(false);
			setShowErrors(false);

			Http.post("/api/order", {
				name: (role===ROLE_DRIVER || role===ROLE_WASHER) ? company : auth.user.name,
				leadNumber,
				pupNumber,
				description,
				date: dayjs(date).format("YYYY-MM-DD HH:mm"),
				pickup,
				drop,
				singleTrailerNo: isSingleTrailer ? singleTrailerNo : ''
			})
				.then((data) => {
					if (data.data) {
						if (isSingleTrailer && singleTrailerNo) {
							Http.post("/api/single_trailer", {
								No: singleTrailerNo
							})
							.then(data => {
								console.log('Successfully added new single trailer number');
							})
							.catch(err => {
								console.log(err);
							})
						}

						toast.success("Request successfully submitted.", {
							hideProgressBar: true,
						});
						socket.send("create_request");
						refreshList();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const isSingleTrailerNoFollowingPattern = (pSTrailerNo) => {
		const regex = /^sTrailer\d+$/;
		return regex.test(pSTrailerNo);
	};

	return (
		<Box
			sx={{
				flexGrow: 1,
				py: 8,
				backgroundColor: "#F3F6F9", // Add a light, modern background color
				borderRadius: "15px", // Round the corners for a modern look
				overflow: "auto", // enable scroll if content overflows
				height: "100vh", // occupy full viewport height
				boxSizing: "border-box", // ensure padding and border are included in element's total height and width
				width: 'min-content'
			}}
			role="presentation"
		>
			<Container maxWidth="lg">
				<Typography
					component="h1"
					variant="h4"
					align="center"
					style={{
						// color: "#3f51b5",
						fontWeight: 600,
						margin: "20px 0",
						textTransform: "uppercase",
					}}
				>
					Request Wash
				</Typography>

				<Grid container spacing={3}>
					{(role===ROLE_DRIVER || role===ROLE_WASHER) ? (
					<Grid xs={12} sm={12}>
						<Grid xs={12}>
							<Autocomplete
								disablePortal
								id="combo-box-company"
								options={companies.map((i) => i.Name)}
								onChange={(e, v) => 
									{
										setCompany(v);
										setLeadNumber("");
										setPupNumber("");
									}
								}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!company && showErrors}
										value={company}
										id="outlined-basic"
										name="company"
										label="Company"
										variant="outlined"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					) : (
					<></>
					)}
					<Grid xs={12}>
						<FormControlLabel control=
							{
								<Checkbox disableRipple checked={isSingleTrailer} onChange={onSingleTrailerChecked} />
							}
							label="Single Trailer Wash"
						/>
					</Grid>

					{
					isSingleTrailer ?
					<Grid xs={12} sm={12}>
						<Autocomplete
							freeSolo
							disablePortal
							id="combo-box-single-trailer-no"
							options={singleTrailers.map((i) => i.No)}
							sx={{ marginBottom: 1 }}
							value={singleTrailerNo}
							onChange={(_, v) => setSingleTrailerNo(v)}
							onInputChange={(e, v) => {
								setSingleTrailerNo(v);

								if (!isSingleTrailerNoFollowingPattern(v)) {
									setShowErrors(true);
								} else {
									setShowErrors(false);
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									error={!isSingleTrailerNoFollowingPattern(singleTrailerNo) && showErrors}
									id="outlined-basic"
									name="singleTrailerNo"
									label="Single Trailer No"
									variant="outlined"
									fullWidth
									helperText={!isSingleTrailerNoFollowingPattern(singleTrailerNo) && showErrors ? "Please enter a valid Single Trailer ID like 'sTrailer3452367'" : ''}
								/>
							)}
						/>
					</Grid> 
					:
					<>
						<Grid xs={6} sm={6}>
							<Autocomplete
								disablePortal
								id="combo-box-leadnumber"
								options={trucks.filter(i => i.Company.trim() === company).map(i => i.LeadNumber)}
								value={leadNumber}
								onChange={(e, v) => {
									setLeadNumber(v);
									const truck = trucks.find(i => i.LeadNumber === v);
									truck && setPupNumber(truck.PupNumber);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!leadNumber && showErrors}
										value={leadNumber}
										id="outlined-basic"
										name="lead"
										label="Lead"
										variant="outlined"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid xs={6} sm={6}>
							<Autocomplete
								disablePortal
								id="combo-box-pupnumber"
								options={trucks.filter(i => i.Company.trim() === company).map(i => i.PupNumber)}
								onChange={(e, v) => setPupNumber(v)}
								value={pupNumber}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!pupNumber && showErrors}
										value={pupNumber}
										id="outlined-basic"
										name="pup"
										label="Pup"
										variant="outlined"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</>
					}

					<Grid xs={12}>
						<Autocomplete
							disablePortal
							id="combo-box-description"
							options={serviceTypes}
							onChange={(e, v) => setDescription(v)}
							renderInput={(params) => (
								<TextField
									{...params}
									value={pickup}
									id="outlined-basic-description"
									name="description"
									label="Service Type"
									variant="outlined"
									fullWidth
									error={!description && showErrors}
								/>
							)}
						/>
					</Grid>

					{
					role !== ROLE_WASHER && 
					(<Grid xs={6} sm={6}>
						<Autocomplete
							disablePortal
							id="combo-box-pickup"
							options={pickups.map(i => i.Name)}
							onChange={(e, v) => setPickup(v)}
							renderInput={(params) => (
								<TextField
									{...params}
									value={pickup}
									id="outlined-basic-pickup"
									name="pickup"
									label="Pickup"
									variant="outlined"
									fullWidth
									error={showErrors}
									// error={(description === SERVICE_TYPE_SHUTTLE1WAY || description === SERVICE_TYPE_SHUTTLE2WAY) && !pickup}
								/>
							)}
						/>
					</Grid>)
					}

					{ 
					role !== ROLE_WASHER && 
					(<Grid xs={6} sm={6}>
						<Autocomplete
							disablePortal
							id="combo-box-drop"
							options={drops.map(i => i.Name)}
							onChange={(e, v) => setDrop(v)}
							renderInput={(params) => (
								<TextField
									{...params}
									value={drop}
									id="outlined-basic-drop"
									name="drop"
									label="Drop"
									variant="outlined"
									fullWidth
									error={showErrors}
									// error={(description === SERVICE_TYPE_SHUTTLE1WAY || description === SERVICE_TYPE_SHUTTLE2WAY) && !drop}
								/>
							)}
						/>
					</Grid>)
					}
					
					<Grid xs={12} md={6}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateCalendar
								value={date}
								onChange={(newValue) => setDate(dayjs(newValue))}
								sx={{ width: "270px" }}
							/>
							{/* <MobileTimePicker 
								value={date}
								onChange={(newValue) => setDate(dayjs(newValue))}
								sx={{ width: "270px", marginBottom: 1 }}
							/> */}
							<FormControl fullWidth variant="outlined" style={{ width: "270px", marginBottom: 10 }}>
								<InputLabel id="time-select-label">Select Time</InputLabel>
								<Select
									value={selectedTime}
									onChange={(event) => {
										const updatedDateTime = date.format("YYYY-MM-DD") + " " + event.target.value;
										setDate(dayjs(updatedDateTime, 'YYYY-MM-DD hh:mm A'))
										setSelectedTime(event.target.value)
									}}
								>
									{timeIntervals.map((time, idx) => (
										<MenuItem key={idx} value={time}>
											{time}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</LocalizationProvider>
					</Grid>
				</Grid>

				<Button
					variant="contained"
					color="inherit"
					startIcon={<Iconify icon="fa:send" />}
					onClick={validate}
					style={{
						color: "white",
						textTransform: "uppercase",
						padding: "10px 20px",
						fontSize: "0.875rem",
						boxShadow: "0px 3px 5px 2px rgba(63,81,181, .3)",
						marginBottom: "10px",
						width: '100%'
					}}
					size="small"
				>
					Send
				</Button>
			</Container>
		</Box>
	);
};
