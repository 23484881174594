import React, { useState } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/system";

import Main from "../../../pages/admin/main";
import Header from "../header";
import NavDefault from "./nav_default";

const PageLayout = () => {
	const [openNav, setOpenNav] = useState(false);

	return (
		<>
			<Header onOpenNav={() => setOpenNav(true)} />
			<Box
				sx={{
					minHeight: 1,
					display: "flex",
					flexDirection: { xs: "column", lg: "row" },
				}}
			>
				<NavDefault openNav={openNav} onCloseNav={() => setOpenNav(false)} />
				<Main>
					<Outlet />
				</Main>
			</Box>
		</>
	);
};

PageLayout.propTypes = {
	children: PropTypes.node,
};

PageLayout.defaultProps = {
	children: null,
};

export default PageLayout;
