export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// signup
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

// logout
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

//Loading state
export const TOGGLE_LOADING = "TOGGLE_LOADING";